<template>
  <div>
    <app-loader></app-loader>
    <layout-vertical>
      <router-view />
    </layout-vertical>
  </div>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
